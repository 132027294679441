/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'human-male-height': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7 2c1.78 0 2.67 2.16 1.42 3.42C7.16 6.67 5 5.78 5 4a2 2 0 012-2M5.5 7h3a2 2 0 012 2v5.5H9V22H5v-7.5H3.5V9a2 2 0 012-2M21 8h-6v2h6m0 1h-3v2h3m0-11h-6v2h6m0 1h-3v2h3m0 7h-6v2h6m0 4h-6v2h6m0-5h-3v2h3"/>'
  }
})
